import { Form, Button, Row, Col, Table, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import axiosInstance from '../general/axiosInstance';
import React, { useEffect, useState } from 'react';
import { setIsLoading } from '../general/actions';
import { currencyFormater, downloadExcel } from '../general/utils';
import SelectAgreement from '../general/selectAgreement'

import Print from '../order/print';
import Log from '../order/log';

function OrdersReport(props) {
    const { setIsLoading, isLoading } = props;

    const [orders, setOrders] = useState([]);

    // Print
    const [idPrint, setIdPrint] = useState(false);
    const [showPrint, setShowPrint] = useState(false);

    // Log
    const [idOrderLog, setIdOrderLog] = useState(false);
    const [showLog, setShowLog] = useState(false);

    // Lists
    const [productTypes, setProductTypes] = useState([]);
    const [statuses, setStatuses] = useState([]);

    // Filters
    const [orderId, setOrderId] = useState('');
    const [agreementFilter, setAgreementFilter] = useState(0);
    const [productType, setProductType] = useState('');
    const [status, setStatus] = useState('');
    const [onlyMyOrders, setOnlyMyOrders] = useState(false);
    const [deliveryAtStart, setDeliveryAtStart] = useState('');
    const [deliveryAtEnd, setDeliveryAtEnd] = useState('');
    const [createdAtStart, setCreatedAtStart] = useState('');
    const [createdAtEnd, setCreatedAtEnd] = useState('');
    
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('warning');

    const clearFilters = () => {
        setOrderId('');
        setProductType('');
        setStatus('');
        setOnlyMyOrders(false);
        setDeliveryAtStart('');
        setDeliveryAtEnd('');
        setCreatedAtStart('');
        setCreatedAtEnd('');
    }

    // Initialized
    useEffect(() => {
        if (!productTypes.length) {
            getProductTypes();
        }
        if (!statuses.length) {
            getProductTypes();
            getStatuses();
        }
        // Set default delivery dates for a month
        let today = new Date();
        setDeliveryAtStart(today.toISOString().split('T')[0]);
        today.setDate(today.getDate() + 30);
        setDeliveryAtEnd(today.toISOString().split('T')[0]);
    }, []);

    useEffect(() => {
        if (agreementFilter) {
            getOrders();
            setOrderId('');
        } else {
            setOrders([]);
            setMessageType('info');
            setMessage('Seleciona un contrato para ver los pedidos');
        }
    }, [agreementFilter]);

    const getStatuses = () => {
        setIsLoading(true);
        axiosInstance.get('/order-statuses')
            .then(res => {
                setStatuses(res.data);
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
                console.log(err);
            });
    }

    const getProductTypes = () => {
        setIsLoading(true);
        axiosInstance.get('/product-types')
            .then(res => {
                setProductTypes(res.data);
                setIsLoading(false);
            })
            .catch(err => {
                setIsLoading(false);
                console.log(err);
            });
    }

    const getOrders = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get('/orders/filters/', { params: {
                agreement_id: agreementFilter,
                id: orderId,
                my_orders: onlyMyOrders,
                order_status_id: status,
                created_start_date: createdAtStart,
                created_end_date: createdAtEnd,
                delivery_start_date: deliveryAtStart,
                delivery_end_date: deliveryAtEnd,
            }});
            console.log(response.data);

            setOrders(response.data);

            if (response.data.length) {
                setMessage('');
            } else {
                setMessageType('info');
                setMessage('No hay resultados con los filtros seleccionados');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>

            <Col md={12} xs={12}>
                <Row>
                    <Col md={12} xs={12}> <legend>Reporte de Pedidos</legend></Col>
                    <Col md={5} xs={12}>
                        <Form.Label>Contrato</Form.Label>
                        <SelectAgreement agreement={agreementFilter} setAgreement={setAgreementFilter} disabled={false} />
                    </Col>
                    <Col md={1} xs={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Pedido</Form.Label>
                            <Form.Control
                                type="number"
                                value={orderId}
                                disabled={!agreementFilter}
                                onChange={(e) => setOrderId(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                    <Col md={2} xs={6} >
                        <Form.Group className="mb-3">
                            <Form.Label>Tipo de producto</Form.Label>
                            <Form.Select value={productType} onChange={(e) => setProductType(e.target.value)} disabled={!agreementFilter}>
                                <option key='productType' value={0}>
                                    Todos
                                </option>
                                {productTypes.map(productType => (
                                    <option key={'productType' + productType.id} value={productType.id}>
                                        {productType.id}: {productType.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={2} xs={6} >
                        <Form.Group className="mb-3">
                            <Form.Label>Estado</Form.Label>
                            <Form.Select value={status} onChange={(e) => setStatus(e.target.value)} disabled={!agreementFilter}>
                                <option key='status' value={0}>
                                    Todos
                                </option>
                                {statuses.map(status => (
                                    <option key={'status' + status.id} value={status.id}>
                                        {status.id}: {status.description}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={2} xs={6} >
                        <Form.Group className="mb-3" controlId="visible">
                            <Form.Label> </Form.Label>
                            <Form.Check type="checkbox" label="Solo mis pedidos" checked={onlyMyOrders} disabled={!agreementFilter}
                             onChange={(e) => setOnlyMyOrders(e.target.checked)} 
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md={1} xs={12} >
                        <Form.Label>Fecha de entrega</Form.Label>
                    </Col>
                    <Col md={2} xs={12}>
                        <Form.Control
                            type="date"
                            value={deliveryAtStart} disabled={!agreementFilter}
                            onChange={(e) => setDeliveryAtStart(e.target.value)}
                        />
                    </Col>
                    <Col md={2} xs={12}>
                        <Form.Control
                            type="date"
                            value={deliveryAtEnd} disabled={!agreementFilter}
                            onChange={(e) => setDeliveryAtEnd(e.target.value)}
                        />
                    </Col>
                    <Col md={1} xs={12} >
                        <Form.Label>Fecha de Creación</Form.Label>
                    </Col>
                    <Col md={2} xs={12}>
                        <Form.Control
                            type="date"
                            value={createdAtStart} disabled={!agreementFilter}
                            onChange={(e) => setCreatedAtStart(e.target.value)}
                        />
                    </Col>
                    <Col md={2} xs={12}>
                        <Form.Control
                            type="date"
                            value={createdAtEnd} disabled={!agreementFilter}
                            onChange={(e) => setCreatedAtEnd(e.target.value)}
                        />
                    </Col>
                    <Col md={2} xs={12}>
                        <Button variant="outline-dark" title='Limpiar filtros' onClick={clearFilters}>
                            <i className='fa fa-close' />
                        </Button>
                        {' '}
                        <Button variant="dark" onClick={getOrders}>
                            <i className='fa fa-filter' /> Filtrar
                        </Button>
                    </Col>
                </Row>
                <hr />
                <br />
                {orders.length ? <>
                    <div align="end">
                        <Button variant="success" onClick={() => {
                            setIsLoading(true);
                            downloadExcel('orders', 'Reporte de pedidos', () => setIsLoading(false))
                        }}>
                            <i className='fa fa-file' /> Descargar
                        </Button>
                        {' '}
                        <Button variant="primary" title='Actualizar Resultados' onClick={() => getOrders()}>
                            <i className='fa fa-rotate-right' />
                        </Button>
                    </div>
                    <Table responsive className="no-print" size={'md'} striped id='orders'>
                        <thead>
                            <tr >
                                <th></th>
                                <th>Pedido</th>
                                <th>Estado</th>
                                <th>Centro de costos</th>
                                <th>Tipo Producto</th>
                                <th>Producto</th>
                                <th>Precio</th>
                                <th>Cantidad</th>
                                <th>IVA/Impuesto</th>
                                <th>Valor Total</th>
                                <th>Persona Recibe</th>
                                <th>Fecha Entrega</th>
                                <th>Hora Entrega</th>
                                <th>Evento</th>
                                <th>Dirección</th>
                                <th>Notas</th>
                                <th>Fondo</th>
                                <th>Fecha Creación</th>
                                <th>Fecha Modificación</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map(item => (
                                <React.Fragment key={'item-' + item.id}>
                                    {item.order_detail.map(detail => (
                                        <tr key={'orders' + detail.id}>
                                            <td align='center'>
                                                <Button onClick={() => {setShowLog(true); setIdOrderLog(item.id)} }
                                                    variant="outline-info" title="Ver log de cambios"
                                                >
                                                    <i className='fa fa-history'/>
                                                </Button>{' '}
                                                <Button variant="warning" onClick={() => { setIdPrint(item.id); setShowPrint(true); }}><i className='fa fa-print' /></Button>
                                            </td>
                                            <td>{item.id}</td>
                                            <td>{item.order_status.id}: {item.order_status.description}</td>
                                            <td> {item.fund.department.id}: {item.fund.department.name}</td>
                                            <td>{detail.product.product_type.id}:{detail.product.product_type.name}</td>
                                            <td>{detail.product.id}:{detail.product.name}</td>
                                            <td>
                                                {isLoading ? 
                                                     currencyFormater(Math.round(parseFloat(detail.price) * ((parseFloat(detail.taxes) / 100) + 1)))
                                                : 
                                                    currencyFormater(Math.round(parseFloat(detail.price) * ((parseFloat(detail.taxes) / 100) + 1)))
                                                }
                                            </td>
                                            <td>{detail.quantity}</td>
                                            <td>{detail.taxes}%</td>
                                            <td>
                                                {isLoading ? detail.total_amount : currencyFormater(detail.total_amount)}
                                            </td>
                                            <td>{item.delivery_person_receiving} Teléfono: {item.delivery_phone}</td>
                                            <td>{item.delivery_date}</td>
                                            <td>{item.delivery_time}</td>
                                            <td>{item.delivery_event_name}</td>
                                            <td>{item.delivery_address}</td>
                                            <td>{item.notes}</td>
                                            <td>{item.fund_id}: {item.fund.ext_id}</td>
                                            <td>{item.created_at}</td>
                                            <td>{item.updated_at}</td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </Table>
                </>
                    : ''}
                {message &&
                    <Alert variant={messageType || 'warning'}>
                        {message}
                    </Alert>
                }
            </Col>
            <Print show={showPrint} setShow={setShowPrint} idPrint={idPrint} setIdPrint={setIdPrint} />
            <Log show={showLog} setShow={setShowLog} orderId={idOrderLog} setOrderId={setIdOrderLog} />
        </>)
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
    };
}

const mapDispatchToProps = {
    setIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersReport);