import { Form, InputGroup, Button, Row, Col, Table, Alert, FormSelect } from 'react-bootstrap';
import { connect } from 'react-redux';
import axiosInstance from '../general/axiosInstance';
import React, { useEffect, useState } from 'react';
import { setIsLoading } from '../general/actions';
import { currencyFormater, downloadExcel } from '../general/utils';
import SelectAgreement from '../general/selectAgreement'

function MonthlyReport(props) {
    const { setIsLoading, isLoading } = props;

    const [funds, setFunds] = useState([]);

    const [searchText, setSearchText] = useState('');
    const [agreementFilter, setAgreementFilter] = useState();

    const [yearFilter, setYearFilter] = useState(new Date().getFullYear());
    const [monthFilter, setMonthFilter] = useState(new Date().getMonth());
    const years = (()=>{
        let years = [];
        for (let i = 2023; i < 2050; i++) {
            years.push(i);
        }
        return years;
    })();

    const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

    const [amountTotal, setAmountTotal] = useState(0);

    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('warning');

    useEffect(() => {
        if (agreementFilter) {
            setSearchText('');
            getFunds();
        } else {
            setFunds([]);
            setMessageType('info');
            setMessage('Seleciona un contrato para ver el reporte');
        }
    }, [agreementFilter, yearFilter, monthFilter]);

    useEffect(() => {
        setAmountTotal(funds.reduce((acc, item) => acc + parseFloat(item.total_amount), 0));
    }, [funds]);

    const getFunds = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.get('/reports/monthly/' + agreementFilter, { params: {
                year: yearFilter,
                month: parseInt(monthFilter) + 1
            }});

            console.log(response.data);

            setFunds(response.data);

            if (response.data.length) {
                setMessage('');
            } else {
                setMessageType('warning');
                setMessage('El contrato seleccionado no tiene pedidos en el periodo seleccionado');
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>

            <Col md={12} xs={12}>
                <Row>
                    <Col md={12} xs={12}> <legend>Reporte de Consolidado Mensual</legend></Col>
                    <Col md={2} xs={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Año</Form.Label>
                            <Form.Select value={yearFilter} onChange={(e) => setYearFilter(e.target.value)}>
                                {years.map(year =>(
                                    <option key={year} value={year}>{year}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={2} xs={6}>
                        <Form.Group className="mb-3">
                            <Form.Label>Mes</Form.Label>
                            <Form.Select value={monthFilter} onChange={(e) => setMonthFilter(e.target.value)}>
                                {months.map((month, index) =>(
                                    <option key={month} value={index}>{month}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                    </Col>
                    <Col md={8} xs={12}>
                        <Form.Label>Contrato</Form.Label>
                        <SelectAgreement agreement={agreementFilter} setAgreement={setAgreementFilter} disabled={false}/>
                    </Col>
                </Row>
                <hr />
                <br />
                {funds.length ? <>
                <div align="end">
                    <Button variant="success" title="Descargar en excel" onClick={() => { 
                        setIsLoading(true);
                        downloadExcel('funds', 'Reporte mensual', ()=> setIsLoading(false));
                    }}>
                        <i className='fa fa-file' /> Descargar
                    </Button>
                    {' '}
                    <Button variant="primary" title='Actualizar Resultados' onClick={() => getFunds()}>
                        <i className='fa fa-rotate-right' />
                    </Button>
                </div>
                <Table responsive className="table table-hover" id='funds'>
                    <thead>
                        <tr >
                            <th>Centro de Costos</th>
                            <th colSpan={2}>Fondo</th>
                            <th>Id Producto</th>
                            <th>Producto</th>
                            <th>Id Pedido</th>
                            <th>Cantidad</th>
                            <th>Valor</th>
                        </tr>
                    </thead>
                    <tbody>
                        {funds.length && funds.map(item => (
                            <tr key={'funds' + item.product_id + item.orders}>
                                <td>{item.department_id}{':'}{item.department}</td>
                                <td>{item.fund_id}:{item.fund_ext_id}</td>
                                <td>{item.fund_name}</td>
                                <td>{item.product_id}: {item.product_ext_id}</td>
                                <td>{item.product}</td>
                                <td>{item.orders}</td>
                                <td>{item.quantity}</td>
                                <td>
                                    {isLoading ? parseFloat(item.total_amount) : currencyFormater(parseFloat(item.total_amount))}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                    {!searchText ? 
                    <tfoot>
                        <tr className="table-dark">
                            <th colSpan={7}>Total:</th>
                            <th>
                                {isLoading ? amountTotal : currencyFormater(amountTotal)}
                            </th>
                        </tr>
                    </tfoot>
                    : ''}
                </Table>
                </>
                : ''}
                {message &&
                    <Alert variant={messageType || 'warning'}>
                        {message}
                    </Alert>
                }
            </Col>

        </>)
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
    };
}

const mapDispatchToProps = {
    setIsLoading
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyReport);