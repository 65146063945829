import { Form, Button, Row, Col, Alert, Modal, Badge } from 'react-bootstrap';
import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import axiosInstance from '../general/axiosInstance';
import { setIsLoading } from '../general/actions';
import { default as Catalog } from '../catalog/products';
import { currencyFormater, LOGO_IMG_PATH } from '../general/utils';

import { useParams, useNavigate, Link } from 'react-router-dom';

import OrderDetails from './orderDetails';
import AlertModal from '../general/alertModal';

import Print from '../order/print';

function OrderCreate(props) {
    const navigate = useNavigate();

    const { setIsLoading, roles, readonly, is_provider } = props;

    const { agreementSel, productSel } = useParams();

    const [preselectedProduct, setPreselectedProduct] = useState(productSel);

    const [orderId, setOrderId] = useState(props.orderId ? props.orderId : '');
    const [createdAt, setCreatedAt] = useState('');

    const [minHoursDeliver, setMinHoursDeliver] = useState(12);

    // Print
    const [idPrint, setIdPrint] = useState(false);
    const [showPrint, setShowPrint] = useState(false);

    // Products Modal
    const [showProductsModal, setShowProductsModal] = useState(false);

    // Totals
    const [initFundVal, setInitFundVal] = useState(null);
    const [totalOrder, setTotalOrder] = useState(0);
    const orderFundVal = useMemo(() => {
        return (initFundVal || 0) - totalOrder;
    }, [initFundVal, totalOrder]);

    const [itemsToDelete, setItemsToDelete] = useState([]);

    // Alert Modal
    const [alertModalShow, setAlertModalShow] = useState(false);
    const [alertModalMsg, setAlertModalMsg] = useState('');
    const [alertModalType, setAlertModalType] = useState('');
    const [alertModalOnConfirm, setAlertModalOnConfirm] = useState(null);
    const [alertModalOnClose, setAlertModalOnClose] = useState(null);

    // Alert Msg
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState('warning');

    // Data from server
    const [agreements, setAgreements] = useState([]);
    const [funds, setFunds] = useState([]);


    const addProduct = (item) => {
        const itemExist = details.some(i => i.id === item.id);
        if (itemExist) {
            setAlertModalMsg('El producto seleccionado ya esta en el pedido');
            setAlertModalShow(true);
            setShowProductsModal(false);
            return;
        }

        item['quantity'] = item['quantity'] || 1;
        setDetails([...details, item]);
        setShowProductsModal(false);
    }

    const getDetailsData = () => {
        let items = [];

        details.forEach(item => {

            let newDetail = {
                product_id: item.id,
                fund_id: fund,
                quantity: item.quantity,
                price: item.price,
                total_amount: item.price * item.quantity,
                taxes: item.taxes,
                notes: '',
            };

            if (item.id_detail) {
                newDetail.id = item.id_detail;
            }
            if (orderId) {
                newDetail.order_id = orderId;
            }
            items.push(newDetail);
        });

        return items;
    }

    const setInitialState = (index) => {

        if (index === 'order_status') {
            let status = props.orderData && props.orderData[index] ? props.orderData[index].description : '';
            return status;
        }

        if (!props.orderData && index === 'details') {
            return [];
        }

        if (!props.orderData) {
            return '';
        }

        if (index === 'details') {
            return props.orderData.order_detail;
        }

        if (props.orderData[index]) {
            return props.orderData[index];
        } else {
            return '';
        }
    }

    // Fields
    const [fund, setFund] = useState(setInitialState('fund_id'));
    const [agreement, setAgreement] = useState(setInitialState('agreement_id'));
    const [status, setStatus] = useState(setInitialState('order_status'));
    const [personReceiving, setPersonReceiving] = useState(setInitialState('delivery_person_receiving'));
    const [personReceivingPhone, setPersonReceivingPhone] = useState(setInitialState('delivery_phone'));
    const [deliveryDate, setDeliveryDate] = useState(setInitialState('delivery_date'));
    const [deliveryTime, setDeliveryTime] = useState(setInitialState('delivery_time'));
    const [deliveryAddress, setDeliveryAddress] = useState(setInitialState('delivery_address'));
    const [deliveryEvent, setDeliveryEvent] = useState(setInitialState('delivery_event_name'));
    const [notes, setNotes] = useState(setInitialState('notes'));
    const [details, setDetails] = useState(setInitialState('details'));

    const loadAgreements = () => {
        setIsLoading(true);
        axiosInstance.get('/agreements/current-user')
            .then(res => {
                setIsLoading(false);
                formatFunds(res.data);
                setAgreements(res.data);
            })
            .catch(err => {
                setIsLoading(false);
                console.log(err);
            });
    }

    const orderFields = () => {
        return {
            agreement_id: agreement,
            delivery_date: deliveryDate,
            delivery_time: deliveryTime,
            delivery_address: deliveryAddress,
            delivery_person_receiving: personReceiving,
            delivery_phone: personReceivingPhone,
            delivery_event_name: deliveryEvent,
            fund_id: fund,
            notes: notes,
            details: getDetailsData(),
            items_delete: itemsToDelete,
        };
    }

    const updateOrder = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.put(`/orders/${orderId}`, orderFields());
            setDetailsId(response.data);
            setAlertModalShow(true);
            setAlertModalMsg('Pedido Actualizado');
            setStatus('Creado');
        } catch (error) {
            setAlertModalShow(true);
            setAlertModalMsg(error.message);
        } finally {
            setIsLoading(false);
        }
    }

    const setDetailsId = (data) => {

        setDetails(details.map(x => {
            if (data.details[x.id]) {
                return { ...x, id_detail: data.details[x.id] };
            } else {
                return { ...x };
            }
        }));
    }

    const createOrder = async () => {
        setIsLoading(true);
        try {
            const response = await axiosInstance.post(`/orders`, orderFields());
            setOrderId(response.data.id);
            setDetailsId(response.data);
            setAlertModalShow(true);
            setAlertModalMsg('Pedido creado con número: ' + response.data.id);
        } catch (error) {
            setAlertModalShow(true);
            if (error.response.data.message.indexOf('Duplicate') > 0) {
                setAlertModalMsg('Pedido ya existe');
            } else if (error.response.data.message.indexOf('Fund is inactive') > 0) {
                setAlertModalMsg('El fondo seleccionado esta inactivo');
            } else {
                setAlertModalMsg(error.message);
            }
        } finally {
            setIsLoading(false);
        }
    }

    const formatFunds = (data) => {
        let funds = [];

        if (fund) {
            return getFundById(fund);
        }

        data.forEach(agreement => {

            agreement.fund.forEach(item => {
                if (item.user_fund.length) {
                    funds.push({
                        'id': item.id,
                        'name': item.name,
                        'available': item.available,
                        'ext_id': item.ext_id,
                        'agreement_id': item.agreement_id,
                        'agreement_ext_id': agreement.ext_id,
                        'is_active': item.is_active,
                    })
                }
            });
        });

        setFunds(funds);
    }

    const getFundById = async (id) => {
        setIsLoading(true);
        try {
            let response = axiosInstance.get('funds', id);

            let funds = (await response).data;

            setFunds(funds);

        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    const deleleteOrder = async (e, confirmed) => {

        if (!confirmed) {
            setAlertModalShow(true);
            setAlertModalMsg('¿Esta seguro de eliminar el pedido ' + orderId + '?');
            setAlertModalOnConfirm(()=>()=>{deleleteOrder(null, true)});
            return;
        }

        setIsLoading(true);
        try {
            const response = await axiosInstance.delete(`/orders/${orderId}`);

            setAlertModalShow(true);
            setAlertModalMsg('Pedido ' + orderId + ' anulado correctamente');
            setAlertModalOnClose(()=>()=>{navigate("/main")});

        } catch (error) {
            setAlertModalShow(true);

            setAlertModalMsg(error.message);
        } finally {
            setIsLoading(false);
        }
    }

    const saveOrder = (e, confirmed) => {
        e.preventDefault();

        if (!confirmed) {
            const arrDate = deliveryDate.split('-');
            const date = `${arrDate[2]}/${arrDate[1]}/${arrDate[0]}`;
            setAlertModalShow(true);
            setAlertModalType('');
            setAlertModalMsg('El pedido sera entregado el dia  ' + date +' a las '+ deliveryTime+ ' horas. ¿Es correcto?');
            setAlertModalOnConfirm(()=>()=>{saveOrder(e, true)});
            return;
        }

        if(!isValidFormData()) {
            setAlertModalShow(true);
            setAlertModalType('danger');
            setAlertModalMsg('El plazo minimo de entrega es de ' + minHoursDeliver  + ' horas');
            return;
        }

        if (orderId) {
            updateOrder();
        } else {
            createOrder();
        }
    }

    const getHoursToDeliver = () => {
        const specificDate = new Date(deliveryDate+"T"+deliveryTime);
        const now = new Date();
        const differenceMs = specificDate.getTime() - now.getTime();
        const differenceHrs = differenceMs / (1000 * 60 * 60);
    
        return differenceHrs;
    }

    const isValidFormData = ()=>{
        setMessageType('warning');
        if (!agreement) {
            return setMessage('Contrato es obligatorio');
        } else if (!deliveryDate) {
            setMessage('Fecha es obligatorio');
            return false;
        } else if (!deliveryTime) {
            setMessage('Hora es obligatorio');
            return false;
        } else if (getHoursToDeliver() < minHoursDeliver && !roles.includes('provider_order')) {
            setMessage('El plazo minimo de entrega es de ' + minHoursDeliver  + ' horas');
            return false;
        } else if (!fund) {
            setMessage('Fondo es obligatorio');
            return false;
        } else if (!deliveryAddress) {
            setMessage('Dirección de entrega es obligatorio');
            return false;
        } if (!personReceiving) {
            setMessage('Persona que recibe es obligatorio');
            return false;
        } else if (!personReceivingPhone) {
            setMessage('Teléfono Persona que recibe es obligatorio');
            return false;
        } else if (!details.length) {
            setMessage('Debes agregar almenos un producto');
            return false;
        } else if (!details.map(detail => detail.quantity === 0).length) {
            setMessage('Debes agregar almenos un producto');
            return false;
        } else {
            setMessage('');
            return true;
        }
    };

    // Initialization
    useEffect(() => {
        if (!agreements.length) {
            loadAgreements();
        }
    }, []);

    useEffect(() => {
        isValidFormData();
    }, [agreement, deliveryDate, deliveryTime, fund, deliveryAddress, personReceiving, personReceivingPhone, details]);

    useEffect(() => {
        if (agreements && !orderId) {
            setDetails([]);
        } else if(agreement && agreements.length) {
            setMinHoursDeliver(agreements.filter(x => x.id === parseInt(agreement))[0].min_hours_deliver);
        }
    }, [agreement, agreements]);

    useEffect(() => {
        if (agreementSel && agreements.length) {
            setAgreement(agreementSel);
            setMinHoursDeliver(agreements.filter(agreement => agreement.id === parseInt(agreementSel))[0].min_hours_deliver);
        }
    }, [agreements, agreementSel]);

    useEffect(() => {
        if (agreement && preselectedProduct) {
            setShowProductsModal(true);
        }
    }, [agreement, preselectedProduct, agreements]);

    useEffect(() => {
        isValidFormData();
    }, [minHoursDeliver]);

    useEffect(() => {
        if (funds.length) {
            let available = parseFloat(funds.filter(x => x.id == parseInt(fund)).map(fund => fund.available)[0]) || 0;

            if (initFundVal === null) {
                setInitFundVal(available + totalOrder);
            }
        }
    }, [funds, totalOrder, fund, agreement]);

    useEffect(() => {
        setOrderId(props.orderId);
        setCreatedAt(props.orderData ? props.orderData.created_at : '');
        setStatus(setInitialState('order_status'));
        setFund(setInitialState('fund_id'));
        setAgreement(setInitialState('agreement_id'));
        setPersonReceiving(setInitialState('delivery_person_receiving'));
        setPersonReceivingPhone(setInitialState('delivery_phone'));
        setDeliveryDate(setInitialState('delivery_date'));
        setDeliveryTime(setInitialState('delivery_time'));
        setDeliveryAddress(setInitialState('delivery_address'));
        setDeliveryEvent(setInitialState('delivery_event_name'));
        setNotes(setInitialState('notes'));
        setDetails(setInitialState('details'));
    }, [props.orderId]);

    useEffect(() => {
        if (!readonly && initFundVal  && orderFundVal < 0) {
            setMessageType('danger');
            setMessage('Presupuesto superado en ' + currencyFormater(orderFundVal));

            setAlertModalShow(true)
            setAlertModalMsg('Presupuesto superado en ' + currencyFormater(orderFundVal));
        }
    }, [orderFundVal, initFundVal]);

    useEffect(() => {
        if (orderId && typeof props.setOrderId === 'function') {
            props.setOrderId(orderId);
        }
    }, [orderId]);

    return (
        <>
            <Row>
                <Col md={3} xs={12}>
                    <legend>
                        {agreement ?
                            <img width="60rem" src={LOGO_IMG_PATH + 'agreement-' + agreement + '.png'}/>
                         : ''} {' '}
                        {!orderId && !readonly ? 'Crear ' : ''}
                        {orderId && !readonly ? 'Modificar ' : ''}
                        {readonly ? 'Consultar ' : ''}
                        Pedido {' '}
                        <Badge bg="secondary">{orderId}</Badge>
                    </legend>
                </Col>
                {(orderId) && <>
                    <Col md={3} xs={12}>
                        <legend align="start">
                            <Badge bg="secondary">Fecha creación: {createdAt}</Badge>
                        </legend>
                    </Col>
                    <Col md={3} xs={12}>
                        <legend align="start">
                            <Badge bg="secondary">{' Estado: '} {status}</Badge>
                        </legend>
                    </Col>
                </>
                }
                <Col md={3} xs={12}>
                    <div align="end">
                        {(orderId && !readonly) &&
                            <Button variant="dark" onClick={deleleteOrder} >
                                Anular Pedido
                            </Button>
                        }
                        {' '}
                        {(orderId) &&
                            <Button variant="warning" title="imprimir" onClick={() => { setIdPrint(orderId); setShowPrint(true); }}>
                                <i className='fa fa-print' /> Imprimir
                            </Button>
                        }
                    </div>

                </Col>
                <Row>
                    <hr />
                </Row>
                <Form>
                    <Row>
                        <Col md={8} xs={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Contrato</Form.Label>
                                <Form.Select disabled={readonly || orderId} value={agreement} onChange={(e) => { setAgreement(e.target.value); setFund(''); }}>
                                    <option key={'orderAgreement-' + 0} value="">Selecciona un contrato</option>
                                    {agreements && agreements.map(item => (
                                        <option key={'orderAgreement-' + item.id} value={item.id} onClick={(e) => setAgreement(item.agreement)} title='sdf'>
                                            {is_provider ? 
                                                <>
                                                    {item.id}: {item.ext_id} {item.description} {' - '} {item.customer.id} : {item.customer.ext_id} {item.customer.name}
                                                </>
                                            :
                                                <>
                                                    {item.id}: {item.ext_id} {item.description} {' - '} {item.provider.id} : {item.provider.ext_id} {item.provider.name}
                                                </>
                                            }
                                            
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>

                        <Col md={2} xs={12}>

                            <Form.Group className="mb-3">
                                <Form.Label>Fecha de Entrega</Form.Label>
                                <Form.Control required disabled={readonly} type="date" placeholder="" value={deliveryDate} onChange={(e) => setDeliveryDate(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col md={2} xs={12}>

                            <Form.Group className="mb-3">
                                <Form.Label>Hora de Entrega</Form.Label>
                                <Form.Control disabled={readonly} type="time" placeholder="" value={deliveryTime} onChange={(e) => setDeliveryTime(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>
                                    Fondo {fund && <>
                                        {' con valor disponible: '}
                                        {currencyFormater(initFundVal)}
                                    </>}
                                </Form.Label>
                                <Form.Select disabled={readonly || orderId} value={fund} onChange={(e) => {
                                    setFund(e.target.value);
                                    let available = e.target.options[e.target.selectedIndex].getAttribute('available');

                                    setInitFundVal((parseFloat(available) + (orderId ? totalOrder : 0)) || 0);
                                }}>
                                    <option key={'fund' + 0} value="">Selecciona un fondo</option>
                                    {funds && funds.map(item => (
                                        item.agreement_id == agreement &&
                                        <option key={'fund' + item.id} value={item.id} available={parseFloat(item.available)} disabled={!item.is_active}>
                                            {item.id}:{item.ext_id}{' '} {item.name}
                                            {!orderId ? ' - valor disponible: ' + currencyFormater(item.available) : ''}
                                            {!item.is_active ? ' ----Fondo inactivo----': ''}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>

                        <Col md={6} xs={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Dirección de entrega</Form.Label>
                                <Form.Control disabled={readonly} type="text" id='address' placeholder="" value={deliveryAddress} onChange={(e) => setDeliveryAddress(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col md={6} xs={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Evento</Form.Label>
                                <Form.Control disabled={readonly} type="text" placeholder="" value={deliveryEvent} onChange={(e) => setDeliveryEvent(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} xs={12}>

                            <Form.Group className="mb-3">
                                <Form.Label>Persona que recibe</Form.Label>
                                <Form.Control disabled={readonly} type="text" placeholder="" value={personReceiving} onChange={(e) => setPersonReceiving(e.target.value)} />
                            </Form.Group>
                        </Col>
                        <Col md={6} xs={12}>

                            <Form.Group className="mb-3">
                                <Form.Label>Teléfono Persona que recibe</Form.Label>
                                <Form.Control disabled={readonly} type="text" placeholder="" value={personReceivingPhone} onChange={(e) => setPersonReceivingPhone(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={12}>

                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Notas</Form.Label>
                                <Form.Control disabled={readonly} as="textarea" rows={2} value={notes} onChange={(e) => setNotes(e.target.value)} />
                            </Form.Group>
                        </Col>
                    </Row>
                    <hr></hr>
                </Form>
            </Row>
            <Row>
                {!readonly &&
                    <Col md={12} xs={12}>
                        <Button className="me-2 mb-2" onClick={() => setShowProductsModal(true)} disabled={!agreement}>
                            Agregar Productos
                        </Button>
                    </Col>
                }
            </Row>
            <OrderDetails setTotalOrder={setTotalOrder} totalOrder={totalOrder}
                products={details} setProducts={setDetails} readonly={readonly}
                setItemsToDelete={setItemsToDelete} itemsToDelete={itemsToDelete}>

            </OrderDetails>
            <h3 align="end" style={{display: 'none'}}>
                {'totalOrder: '}
                {currencyFormater(totalOrder)}
            </h3>
            <h3 align="end" style={{display: 'none'}}>
                {'orderFundVal: '}
                {currencyFormater(orderFundVal)}
            </h3>
            <h1 align="end" style={{display: 'none'}}>
                {'initFundVal: '}
                {currencyFormater(initFundVal)}
            </h1>
            <hr></hr>


            <Row>
                {!readonly && message &&
                    <Alert variant={messageType || 'warning'}>
                        {message}
                    </Alert>
                }
                {!readonly &&
                    <Col md={12} xs={12} align="end">
                        <Button variant="dark" onClick={saveOrder} disabled={!!message}>
                            {orderId ? 'Modificar Pedido' : 'Guardar Pedido'}
                        </Button>
                        {' '}
                        <Button as={Link} to="/products" variant="outline-dark">
                            Volver
                        </Button>
                    </Col>
                }
            </Row>

            <Modal show={showProductsModal} fullscreen={true} onHide={() => setShowProductsModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Agregar Productos al Pedido</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Catalog isCreatingOrder={true} addProduct={addProduct} agreement={agreement} productSel={preselectedProduct} setProductSel={setPreselectedProduct}></Catalog>
                </Modal.Body>
            </Modal>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <AlertModal 
                show={alertModalShow} setShow={setAlertModalShow} 
                msg={alertModalMsg} setMsg={setAlertModalMsg} 
                onConfirm={alertModalOnConfirm} setOnConfirm={setAlertModalOnConfirm} 
                onClose={alertModalOnClose} setOnClose={setAlertModalOnClose}
                variant={alertModalType}
            />
            <Print show={showPrint} setShow={setShowPrint} idPrint={idPrint} setIdPrint={setIdPrint} />
        </>
    );
}

function mapStateToProps(state) {
    return {
        isLoading: state.isLoading,
        roles: state.roles,
        is_provider: state.is_provider,
    };
}

const mapDispatchToProps = {
    setIsLoading,
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderCreate);